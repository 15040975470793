import React from "react"
import Layout from "../components/layout"
import styled from "@emotion/styled"
import { graphql } from "gatsby"
import parse from "html-react-parser"
import { options } from "../components/parser"
import {
  TextCard,
  Text,
  SoliWhite,
  NewsDate,
  SoliBlack,
  SoliCard,
  TextCardHeader,
  Anchor,
} from "../components/styles"
import { SingFloat } from "../components/headerlinks"

const TerminCard = styled(SoliCard)`
  text-decoration: none;
`
const TerminText = styled.div`
  text-decoration: none;
  font-weight: 600;
  width: 100%;
  color: black;
  font-size: 1.2rem;
  line-height: 1.4;
  padding: 0.5rem;
  margin: 0;
  font-family: "Alegreya Sans";
  text-align: center;
  padding: 0.5rem 0.5rem;
`

const RightSide = ({
  data: {
    html,
    frontmatter: { title, bild, bildtext },
  },
}) => {
  return (
    <TerminCard>
      {" "}
      <SingFloat />
      <SoliWhite>{title}</SoliWhite>
      <TerminText>{parse(html, options)}</TerminText>
    </TerminCard>
  )
}

const Blog = ({ data: { Termine, Blog } }) => {
  return (
    <Layout rightside={<RightSide data={Termine} />}>
      {Blog.edges.map(({ node: { html, id, frontmatter } }, index) => (
        <TextCard key={id}>
          <Anchor id={frontmatter.title.replace(/[^a-zA-Z0-9]/g, "")} />
          <TextCardHeader>
            <SoliBlack>{frontmatter.title}</SoliBlack>
            <NewsDate>{frontmatter.date}</NewsDate>
          </TextCardHeader>
          <Text className="blog">{parse(html, options)}</Text>
        </TextCard>
      ))}
      
    </Layout>
  )
}

export default Blog

export const query = graphql`
  query BlogQuery {
    Blog: allMarkdownRemark(
      filter: {
        frontmatter: { online: { eq: "ja" }, location: { eq: "blog" } }
      }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          html
          id
          frontmatter {
            date(formatString: "DD. MMMM 'YY", locale: "de-DE")
            title
            online
            location
          }
        }
      }
    }
    Termine: markdownRemark(
      frontmatter: { location: { eq: "blog/#Termine" } }
    ) {
      id
      frontmatter {
        bild
        bildtext
        location
        online
        title
      }
      html
    }
  }
`
